const { REACT_APP_API_HOST: apiHost } = process.env;

export const API_ENDPOINTS = {
  /* Orchids */
  genera: `${apiHost}/public/genera/:GENUS_SLUG`,
  species: `${apiHost}/public/species/:SPECIES_SLUG`,
  genusSpeciesList: `${apiHost}/public/genera/:GENUS_ID/specieslist`,

  /* Images */
  speciesBaseImageUri: `${apiHost}/public/species/:SPECIES_ID/:PHOTO_DATA_ID`,

  /* Pagination */
  generaPage: `${apiHost}/public/page/genera/:PAGE_NUMBER?limit=:LIMIT:QUERY:START_LETTER`,
  speciesPage: `${apiHost}/public/page/species/:PAGE_NUMBER?limit=:LIMIT:QUERY:START_LETTER`,
};
