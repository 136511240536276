import { createSelector } from "reselect";

const generaSelector = (state) => state.genera;

export const makeSelectIsLoading = () =>
  createSelector(
    [generaSelector],
    (genera) =>
      genera.api.loading === "getGenera/pending" ||
      genera.api.loading === "getGenus/pending"
  );

export const makeSelectGeneraList = () =>
  createSelector([generaSelector], (genera) => genera.list);

export const makeSelectGeneraListHasMore = () =>
  createSelector([generaSelector], (genera) => genera.hasMore);

export const makeSelectedGenus = () =>
  createSelector([generaSelector], (genera) => genera.selected);
