import axios from "axios";

function logRequestResponse(response, type) {
  if (process.env.NODE_ENV !== "production") {
    console.group(
      `%c API CALL RESPONSE %creceived @ ${new Date().toLocaleString()}`,
      "color: #93014C",
      "font-weight: normal; color: #7F7E7E"
    );
    let color = "#F54725"; // non-authorized
    if (type === "error") color = "red";
    if (type === "authorized") color = "#CD6AEF";
    console.log(
      `%c${type} %crequest/response:`,
      `font-weight: bold; color: ${color}`,
      "font-weight: normal; color: #7F7E7E",
      response
    );
    console.groupEnd("API REQUEST RESPONSE");
  }
}

export class HttpError extends Error {
  constructor(message, requestConfig, response) {
    super(message);
    this.name = "HttpError";
    this.config = requestConfig;
    this.response = response;
  }
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    logRequestResponse(
      {
        request: error.config,
        response: error.response,
      },
      "error"
    );
    if (error.response && error.response.status === 401) {
      throw new HttpError("401", error.response.config);
    }
    if (
      error.response &&
      error.response.config &&
      error.response.config.url &&
      error.response.status === 400
    ) {
      throw new HttpError("400", error.response.config);
    }
    if (
      error.response &&
      error.response.config &&
      error.response.config.url &&
      error.response.status === 409
    ) {
      throw new HttpError("409", error.response.config, error.response);
    }
    throw new Error(error);
  }
);

function request({ method, endpoint, body = null, headers = {} }) {
  const config = {
    method,
    url: endpoint,
    data: body,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...headers,
    },
    timeout: 30000,
  };

  return axios(config);
}

function nonAuthorizedRequest(params) {
  return request({
    ...params,
  }).then((response) => {
    logRequestResponse(response, "non-authorized");
    if (response.status === 401) {
      throw new Error("Unauthorised");
    }

    return response.data;
  });
}

export default () => ({
  nonAuthRequest: nonAuthorizedRequest,
});
