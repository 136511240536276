import React from "react";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import SpeciesIcon from "@material-ui/icons/FilterVintage";
import GeneraIcon from "@material-ui/icons/LocalFlorist";
import HomeIcon from "@material-ui/icons/Home";
import { APP_ROUTES } from "../lib/constants/app-routes";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

const MENU_ITEMS = [
  {
    id: "species",
    icon: SpeciesIcon,
    label: "Species",
    route: `${APP_ROUTES.speciesList}/A`,
  },
  {
    id: "genera",
    icon: GeneraIcon,
    label: "Genera",
    route: `${APP_ROUTES.generaList}/A`,
  },
  {
    id: "home",
    icon: HomeIcon,
    label: "Main Site",
    onClick: () => window.open("https://yonggee.name/"),
  },
];

export default function TemporaryDrawer() {
  const history = useHistory();
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    event.preventDefault();
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const navigateTo = (route) => () => {
    history.push(route);
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {MENU_ITEMS.map(({ id, icon: Icon, label, onClick, route }, index) => (
          <ListItem
            button
            key={id}
            onClick={
              typeof onClick === "function" ? onClick : navigateTo(route)
            }
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div className="cd-dropdown-wrapper">
      <button
        type="button"
        className="meanmenu-reveal"
        onClick={toggleDrawer("top", true)}
      >
        <span />
        <span />
        <span />
      </button>
      <Drawer
        anchor="top"
        open={state.top}
        onClose={toggleDrawer("top", false)}
      >
        {list("top")}
      </Drawer>
    </div>
  );
}
