import { createMuiTheme } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#001f46'
    },
    secondary: {
      main: '#f44336'
    },
    background: {
      default: '#000'
    },
    text: {
      primary: '#ff84c6',
      secondary: '#FBC338'
    },
    action: {
      selected: 'rgba(255, 132, 198, 0.15)'
    },
    divider: 'rgba(255, 255, 255, 0.05)'
  }
})

export default theme
