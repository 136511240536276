// import React from "react";
// import CloseButton from "../../../components/CloseButton";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { orchidsApi } from "../../api";
// import { enqueueNotification, closeNotification } from "./noistack";

const initialState = {
  list: [],
  hasMore: false,
  selected: null,
  api: {
    currentRequestId: undefined,
    loading: "idle",
    error: null,
  },
};

// Disabled sending notifications in public website
// const notifError =
//   (message, variant = "error") =>
//   (dispatch) => {
//     dispatch(
//       enqueueNotification({
//         message: message,
//         options: {
//           variant,
//           action: (key) => (
//             <CloseButton onClick={() => dispatch(closeNotification(key))} />
//           ),
//         },
//       })
//     );
//   };

export const getGenera = createAsyncThunk(
  "genera/getGenera",
  async (
    { page, limit, query, startLetter },
    { getState, requestId, rejectWithValue, dispatch }
  ) => {
    const {
      api: { currentRequestId, loading },
    } = getState().genera;

    const makeRequest = async () => {
      try {
        const response = await orchidsApi.getGeneraPage({
          page,
          limit,
          query,
          startLetter,
        });

        return response;
      } catch (err) {
        throw new Error(err.message);
      }
    };

    try {
      if (loading !== "getGenera/pending" || requestId !== currentRequestId) {
        return;
      }
      const data = await makeRequest();
      return data;
    } catch (err) {
      // dispatch(notifError(err.message || "Failed to get data"));
      return rejectWithValue(err.message);
    }
  }
);

export const getGenus = createAsyncThunk(
  "genera/getGenus",
  async ({ genusSlug }, { getState, requestId, rejectWithValue, dispatch }) => {
    const {
      api: { currentRequestId, loading },
    } = getState().genera;

    const makeRequest = async () => {
      try {
        const response = await orchidsApi.getGenus({ genusSlug });
        return response;
      } catch (err) {
        throw new Error(err.message);
      }
    };

    try {
      if (loading !== "getGenus/pending" || requestId !== currentRequestId) {
        return;
      }
      const data = await makeRequest();

      return data;
    } catch (err) {
      // dispatch(notifError(err.message || "Failed to get data"));
      return rejectWithValue(err.message);
    }
  }
);

const generaSlice = createSlice({
  name: "genera",
  initialState,
  reducers: {
    clearSelected(state) {
      state.selected = null;
    },
  },
  extraReducers: {
    /* Get Genera */
    [getGenera.pending]: (state, action) => {
      if (state.api.loading === "idle") {
        state.api.loading = "getGenera/pending";
        state.api.currentRequestId = action.meta.requestId;
      }
    },
    [getGenera.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.api.loading === "getGenera/pending" &&
        state.api.currentRequestId === requestId
      ) {
        state.api.loading = "idle";
        state.api.currentRequestId = undefined;

        const { loadMore } = action.meta.arg;

        if (loadMore) {
          state.list = [...state.list, ...action.payload.results];
        } else {
          state.list = [...action.payload.results];
        }

        state.hasMore = !!action.payload.nextPage;
      }
    },
    [getGenera.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.api.loading === "getGenera/pending" &&
        state.api.currentRequestId === requestId
      ) {
        state.api.loading = "idle";
        state.api.error = action.error;
        state.api.currentRequestId = undefined;
      }
    },

    /* Get Genus */
    [getGenus.pending]: (state, action) => {
      if (state.api.loading === "idle") {
        state.api.loading = "getGenus/pending";
        state.api.currentRequestId = action.meta.requestId;
        state.selected = null;
      }
    },
    [getGenus.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.api.loading === "getGenus/pending" &&
        state.api.currentRequestId === requestId
      ) {
        state.api.loading = "idle";
        state.api.currentRequestId = undefined;
        state.selected = { ...action.payload };
      }
    },
    [getGenus.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.api.loading === "getGenus/pending" &&
        state.api.currentRequestId === requestId
      ) {
        state.api.loading = "idle";
        state.api.error = action.error;
        state.api.currentRequestId = undefined;
        state.selected = null;
      }
    },
  },
});

export const { clearSelected } = generaSlice.actions;

export default generaSlice.reducer;
