import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import PageTemplate from "../components/PageTemplate";
import Genus from "../containers/Genera/Genus";
import {
  makeSelectedGenus,
  makeSelectIsLoading,
} from "../lib/store/selectors/genera";
import {
  makeSelectIsLoadingGenusSpeciesList,
  makeSelectGenusSpeciesList,
} from "../lib/store/selectors/species";
import {
  getGenus,
  clearSelected as clearSelectedGenus,
} from "../lib/store/reducers/genera";
import {
  getGenusSpeciesList,
  clearByGenusList as clearByGenusSpeciesList,
} from "../lib/store/reducers/species";

const GenusPage = ({
  selectedGenus,
  genusSpeciesList,
  getGenus,
  getGenusSpeciesList,
  clearSelectedGenus,
  clearByGenusSpeciesList,
  isLoading,
  isLoadingSpeciesList,
}) => {
  const { genusSlug } = useParams();

  const loadGenus = React.useCallback(() => {
    getGenus({ genusSlug });
  }, [getGenus, genusSlug]);

  React.useEffect(() => {
    loadGenus();
  }, [loadGenus]);

  React.useEffect(() => {
    return () => {
      clearSelectedGenus();
      clearByGenusSpeciesList();
    };
  }, [clearSelectedGenus, clearByGenusSpeciesList]);

  const loadGenusSpecies = React.useCallback(() => {
    if (selectedGenus?.id) {
      getGenusSpeciesList({ genusId: selectedGenus?.id });
    }
  }, [getGenusSpeciesList, selectedGenus]);

  React.useEffect(() => {
    loadGenusSpecies();
  }, [loadGenusSpecies]);

  return (
    <PageTemplate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Genus
            genus={selectedGenus}
            speciesList={genusSpeciesList}
            isLoading={isLoading}
            isLoadingSpeciesList={isLoadingSpeciesList}
          />
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

const mapStateToProps = (state) => {
  const selectIsLoadingSpeciesList = makeSelectIsLoadingGenusSpeciesList();
  const selectIsLoading = makeSelectIsLoading();
  const selectedGenus = makeSelectedGenus();
  const selectGenusSpeciesList = makeSelectGenusSpeciesList();

  return {
    isLoading: selectIsLoading(state),
    isLoadingSpeciesList: selectIsLoadingSpeciesList(state),
    selectedGenus: selectedGenus(state),
    genusSpeciesList: selectGenusSpeciesList(state),
  };
};

export default connect(mapStateToProps, {
  getGenus,
  getGenusSpeciesList,
  clearSelectedGenus,
  clearByGenusSpeciesList,
})(GenusPage);
