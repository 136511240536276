import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4 } from 'uuid'

const initialState = []

const noistackSlice = createSlice({
  name: 'noistack',
  initialState,
  reducers: {
    enqueueNotification (state, action) {
      const key = action.payload.options && action.payload.options.key

      state.push({
        ...action.payload,
        key: key || uuidv4()
      })
    },
    closeNotification (state, action) {
      const key = action.payload
      const dismissAll = !key

      return state.map(notification =>
        dismissAll || notification.key === key
          ? { ...notification, dismissed: true }
          : { ...notification }
      )
    },
    removeNotification (state, action) {
      const key = action.payload
      return state.filter(notification => notification.key !== key)
    }
  }
})

export const { enqueueNotification, closeNotification, removeNotification } = noistackSlice.actions

export default noistackSlice.reducer
