import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles(() => ({
  text: {
    color: grey[100],
    fontSize: 22
  },
  divider: {
    width: 10
  },
  icon: {
    color: grey[100],
    fontSize: 22
  }
}))

function NotFound ({ message }) {
  const classes = useStyles()

  return (
    <Box my={2} display='flex' flexDirection='row' justifyContent='center' alignItems='center'>
      <CancelIcon className={classes.icon} />
      <div className={classes.divider} />
      <Typography component='h2' variant='body1' className={classes.text}>
        {message}
      </Typography>
    </Box>
  )
}

NotFound.defaultProps = {
  message: 'Not Found'
}

export default NotFound
