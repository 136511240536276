import { combineReducers } from 'redux'
import genera from './genera'
import noistack from './noistack'
import species from './species'

const rootReducer = combineReducers({
  genera,
  noistack,
  species,
})

export default rootReducer
