import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import debounce from "lodash.debounce";
import GeneraListComponent from "../../components/Genera/GeneraList";
import { getGenera } from "../../lib/store/reducers/genera";
import {
  makeSelectIsLoading,
  makeSelectGeneraList,
  makeSelectGeneraListHasMore,
} from "../../lib/store/selectors/genera";
import { APP_ROUTES } from "../../lib/constants/app-routes";

const { REACT_APP_API_HOST } = process.env;

function GeneraList({ genera, hasMoreGenera, isLoading, getGenera }) {
  const params = useParams();

  const [pagination, setPagination] = React.useState({
    page: 1,
    limit: 25,
    query: params?.letter ?? "a",
    currentLetter: params?.letter ?? "a",
    startLetter: true,
    loadMore: false,
  });

  React.useEffect(() => {
    if (params?.letter !== pagination.currentLetter) {
      setPagination((vals) => ({
        ...vals,
        page: 1,
        query: String(params?.letter).toLowerCase(),
        currentLetter: String(params?.letter).toLowerCase(),
        startLetter: true,
        loadMore: false,
      }));
    }
  }, [params?.letter]); // eslint-disable-line react-hooks/exhaustive-deps

  const getGeneraList = React.useCallback(() => {
    getGenera({
      page: pagination.page,
      limit: pagination.limit,
      query: pagination.query,
      startLetter: pagination.startLetter,
      loadMore: pagination.loadMore,
    });
  }, [getGenera, pagination]);

  React.useEffect(() => {
    getGeneraList();
  }, [getGeneraList]);

  const getGenusUrl = (genusSlug) => {
    return `${APP_ROUTES.genera}/${genusSlug}`;
  };

  // const handleClickLetter = (newLetter) => {
  //   setPagination((vals) => ({
  //     ...vals,
  //     page: 1,
  //     query: String(newLetter).toLowerCase(),
  //     currentLetter: String(newLetter).toLowerCase(),
  //     startLetter: true,
  //     loadMore: false,
  //   }));
  // };

  const handleFilterList = (filter) => {
    if (String(filter).trim().length > 0) {
      setPagination((vals) => ({
        ...vals,
        page: 1,
        query: filter,
        startLetter: false,
      }));
    } else {
      setPagination((vals) => ({
        ...vals,
        page: 1,
        query: vals.currentLetter,
        startLetter: true,
        loadMore: false,
      }));
    }
  };

  const handleLoadMore = () => {
    if (hasMoreGenera) {
      setPagination((vals) => ({
        ...vals,
        page: vals.page + 1,
        loadMore: true,
      }));
    }
  };

  const handleLinkToImage = (imageUri) => {
    return `${REACT_APP_API_HOST + "/public/" + imageUri}`;
  };

  return (
    <GeneraListComponent
      list={genera}
      hasMore={hasMoreGenera}
      isLoading={isLoading}
      linkToImage={handleLinkToImage}
      selectedLetter={params?.letter?.toUpperCase() ?? "A"}
      baseUrl={APP_ROUTES.generaList}
      onGetUrl={getGenusUrl}
      onLoadMore={handleLoadMore}
      onFilterList={debounce(handleFilterList, 500)}
    />
  );
}

const mapStateToProps = (state) => {
  const selectGeneraList = makeSelectGeneraList();
  const selectIsLoading = makeSelectIsLoading();
  const selectGeneraListHasMore = makeSelectGeneraListHasMore();

  return {
    isLoading: selectIsLoading(state),
    genera: selectGeneraList(state),
    hasMoreGenera: selectGeneraListHasMore(state),
  };
};

export default connect(mapStateToProps, {
  getGenera,
})(GeneraList);
