import React from "react";
import sort from "fast-sort";
import SpeciesItem from "../../components/Species/SpeciesItem";
import NotFound from "../../components/NotFound";
import { APP_ROUTES } from "../../lib/constants/app-routes";
import { API_ENDPOINTS } from "../../lib/constants/api-endpoints";

const { REACT_APP_API_HOST } = process.env;

function Species({
  species,
  speciesApiError,
  relatedSpeciesList,
  isLoading,
  isLoadingRelatedSpeciesList,
  isLoadingGenera,
}) {
  const handleGetGenusLink = () => ({
    pathname: `${APP_ROUTES.genera}/${species?.genus?.websiteSlug}`,
    state: { genusName: species?.genus?.genusName },
  });

  const handleLinkToSpecies = (speciesSlug, speciesName) => {
    return {
      pathname: `${APP_ROUTES.species}/${speciesSlug}`,
      state: { speciesName },
    };
  };

  const handleImageUrl = (speciesId, photoDataId, imagePath) => {
    // /public/species/:speciesId/:speciesPhotoDataId/orchids/images/:folder/:file
    const endpoint = API_ENDPOINTS.speciesBaseImageUri
      .replace(/:SPECIES_ID/, speciesId)
      .replace(/:PHOTO_DATA_ID/, photoDataId);
    return `${endpoint}${imagePath}`;
  };

  const handleLinkToSpeciesImage = (
    speciesId,
    websiteFeaturePhotoId,
    photoData
  ) => {
    if (!Array.isArray(photoData)) return null;
    if (!websiteFeaturePhotoId) return null;

    const _photoData = photoData.find((d) => d.id === websiteFeaturePhotoId);

    if (!_photoData) return null;

    const { websitePhotoUri, id } = _photoData;

    if (!websitePhotoUri) return null;

    return `${REACT_APP_API_HOST}/public/species/${speciesId}/${id}${websitePhotoUri}`;
  };

  const isNotFound =
    speciesApiError !== null && species === null && isLoading === false;

  if (isNotFound) {
    return <NotFound message="Species Not Found" />;
  }

  const _relatedSpeciesList =
    Array.isArray(relatedSpeciesList) && species?.genus
      ? sort([...relatedSpeciesList]).asc((sp) => {
          const { websiteFormalName } = sp;
          return String(websiteFormalName).replace(/<[^>]*>?/gm, "");
        })
      : [];

  return (
    <>
      <SpeciesItem
        species={species}
        relatedSpeciesList={_relatedSpeciesList}
        isLoading={isLoading}
        isLoadingRelatedSpeciesList={isLoadingRelatedSpeciesList}
        onImageUri={handleImageUrl}
        onGetGenusLink={handleGetGenusLink}
        onLinkToSpecies={handleLinkToSpecies}
        onLinkToSpeciesImage={handleLinkToSpeciesImage}
      />
    </>
  );
}

Species.defaultProps = {
  relatedSpeciesList: [],
  isLoading: false,
  isLoadingRelatedSpeciesList: false,
  isLoadingGenera: false,
};

export default Species;
