import React from 'react'
import { Grid } from '@material-ui/core'
import PageTemplate from '../components/PageTemplate'
import SpeciesList from '../containers/Species/List'

const SpeciesListPage = () => {
  return (
    <PageTemplate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SpeciesList />
        </Grid>
      </Grid>
    </PageTemplate>
  )
}

export default SpeciesListPage
