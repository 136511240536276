export const APP_ROUTES = {
  generaList: "/genera-list",
  speciesList: "/species-list",

  /* Orchid Paths */
  genera: "/genera",
  species: "/species",
  search: "/search",

  /* Generic Paths */
  notfound: "/notfound",
};
