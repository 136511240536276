import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid, Box, Divider, Typography } from "@material-ui/core";
import Interweave from "interweave";
import Loading from "../LoadingIndicator";
import SpeciesList from "../Species/SpeciesListTable";
import placeholderImage from "../../_assets/images/placeholder.png";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
  },
  list: {
    listStyleType: "none",
    padding: 0,
    marginTop: 0,
  },
  itemRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "no-wrap",
  },
  label: {
    display: "inline-block",
    width: "20%",
    fontSize: "1.1em",
    fontWeight: 600,
    color: "#ff8000",
  },
  itemData: {
    paddingmarginTop: 4,
    paddingBottom: 4,
    paddingLeft: 4,
    width: "80%",
    color: "#cccc00",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  divider: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  relatedSpeciesHeading: {
    color: theme.palette.text.secondary,
    fontSize: "1.2em",
  },
  genusNavLink: {
    color: theme.palette.text.secondary,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  heading: {
    color: theme.palette.text.secondary,
    fontSize: "2em",
  },
  italics: {
    fontStyle: "italic !important",
  },
}));

function Genus({
  genus,
  speciesList,
  linkToImage,
  isLoading,
  isLoadingSpeciesList,
  onLinkToSpecies,
  onLinkToSpeciesImage,
}) {
  const classes = useStyles();

  const fields = [
    {
      id: "author",
      label: "Author",
      data: genus?.author ?? null,
    },
    {
      id: "pubDate",
      label: "Publication Date",
      data: genus?.pubDate ?? null,
    },
    {
      id: "publication",
      label: "Publication",
      data: genus?.publication ?? null,
    },
    {
      id: "synonyms",
      label: "Synonyms",
      data: genus?.synonyms ?? "",
    },
    {
      id: "typeSpecies",
      label: "Type Species",
      data: genus?.typeSpecies ?? null,
    },
    {
      id: "subfamily",
      label: "Subfamily",
      data: genus?.subfamily?.entityName ?? null,
    },
    {
      id: "tribe",
      label: "Tribe",
      data: genus?.tribe?.entityName ?? null,
    },
    {
      id: "subtribe",
      label: "Subtribe",
      data: genus?.subtribe?.entityName ?? null,
    },
    {
      id: "etymology",
      label: "Etymology",
      data: genus?.etymology ?? null,
    },
    {
      id: "abbreviation",
      label: "Abbreviation",
      data: genus?.abbreviation ?? null,
    },
    {
      id: "entityDistribution",
      label: "Distribution",
      data: genus?.entityDistribution ?? null,
    },
    {
      id: "characteristics",
      label: "Characteristics",
      data: genus?.characteristics ?? null,
    },
    {
      id: "notes",
      label: "Notes",
      data: genus?.notes ?? null,
    },
    {
      id: "entityReferences",
      label: "References",
      data: genus?.entityReferences ?? null,
    },
  ];

  // Request from Roger to always make these items italics
  const italicItemIds = {
    typeSpecies: true,
    subfamily: true,
    abbreviation: true,
  };

  return (
    <Box my={1}>
      <Grid container spacing={2} className={classes.root}>
        {isLoading && (
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Loading size={24} />
            </Box>
          </Grid>
        )}
        {!isLoading && genus !== null && (
          <>
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h4"
                className={classes.heading}
              >
                <em>{genus?.genusName}</em>
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <ul className={classes.list}>
                {fields.map(({ id, label, data }) =>
                  data ? (
                    <li key={id} className={classes.listItem}>
                      <div className={classes.itemRoot}>
                        <span className={classes.label}>{label}:</span>
                        <div className={classes.itemData}>
                          <span
                            className={clsx(
                              italicItemIds[id] && classes.italics
                            )}
                          >
                            <Interweave content={String(data)} />
                          </span>
                        </div>
                      </div>
                    </li>
                  ) : null
                )}
              </ul>
            </Grid>
            <Grid item xs={12} md={4}>
              <div>
                <img
                  src={
                    genus?.websitePhotoUri
                      ? linkToImage(genus?.websitePhotoUri)
                      : placeholderImage
                  }
                  className={classes.image}
                  alt={genus.genus}
                />
              </div>
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Divider className={classes.divider} />
        </Grid>
        <Grid item xs={12}>
          <Typography
            component="h2"
            variant="h6"
            className={classes.relatedSpeciesHeading}
          >
            Species
          </Typography>
          <SpeciesList
            genusName={genus?.genusName}
            speciesList={speciesList}
            filterPlaceholder="Filter by Species name"
            isLoading={isLoadingSpeciesList}
            linkToSpecies={onLinkToSpecies}
            linkToSpeciesImage={onLinkToSpeciesImage}
            tableColumns={[
              { id: "species", label: "Species" },
              { id: "publicationsp", label: "Publication" },
              { id: "pubyrsp", label: "Year" },
              { id: "distributionsp", label: "Distribution" },
              // { id: "characteristicsp", label: "Characteristics" },
              // { id: "referencesp", label: "References" },
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Genus;
