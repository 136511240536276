import React from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import SpeciesListComponent from "../../components/Species/SpeciesList";
import { getSpeciesPage } from "../../lib/store/reducers/species";
import {
  makeSelectIsLoading,
  speciesList,
  selectSpeciesListHasMore,
} from "../../lib/store/selectors/species";
import { APP_ROUTES } from "../../lib/constants/app-routes";
import { API_ENDPOINTS } from "../../lib/constants/api-endpoints";

function SpeciesList({ species, isLoading, hasMoreSpecies, onGetPage }) {
  const params = useParams();

  const [pagination, setPagination] = React.useState({
    page: 1,
    limit: 25,
    query: params?.letter ?? "a",
    currentLetter: params?.letter ?? "a",
    startLetter: true,
    loadMore: false,
  });

  React.useEffect(() => {
    if (params?.letter !== pagination.currentLetter) {
      setPagination((vals) => ({
        ...vals,
        page: 1,
        query: String(params?.letter).toLowerCase(),
        currentLetter: String(params?.letter).toLowerCase(),
        startLetter: true,
        loadMore: false,
      }));
    }
  }, [params?.letter]); // eslint-disable-line react-hooks/exhaustive-deps

  const getSpeciesList = React.useCallback(() => {
    onGetPage({
      page: pagination.page,
      limit: pagination.limit,
      query: pagination.query,
      startLetter: pagination.startLetter,
      loadMore: pagination.loadMore,
    });
  }, [onGetPage, pagination]);

  React.useEffect(() => {
    getSpeciesList();
  }, [getSpeciesList]);

  // const handleClickSpecies = (speciesSlug, speciesName) => {
  //   history.push({
  //     pathname: `${APP_ROUTES.species}/${speciesSlug}`,
  //     state: { speciesName },
  //   });
  // };

  const getSpeciesUrl = (speciesSlug) => {
    return `${APP_ROUTES.species}/${speciesSlug}`;
  };

  // const handleClickLetter = (newLetter) => {
  //   setPagination((vals) => ({
  //     ...vals,
  //     page: 1,
  //     query: String(newLetter).toLowerCase(),
  //     currentLetter: String(newLetter).toLowerCase(),
  //     startLetter: true,
  //     loadMore: false,
  //   }));
  // };

  const handleFilterList = (filter) => {
    if (String(filter).trim().length > 0) {
      setPagination((vals) => ({
        ...vals,
        page: 1,
        query: filter,
        startLetter: false,
      }));
    } else {
      setPagination((vals) => ({
        ...vals,
        page: 1,
        query: vals.currentLetter,
        startLetter: true,
        loadMore: false,
      }));
    }
  };

  const handleLoadMore = () => {
    if (hasMoreSpecies) {
      setPagination((vals) => ({
        ...vals,
        page: vals.page + 1,
        loadMore: true,
      }));
    }
  };

  const handleImageUrl = (speciesId, photoDataId, imagePath) => {
    // /public/species/:speciesId/:speciesPhotoDataId/orchids/images/:folder/:file
    const endpoint = API_ENDPOINTS.speciesBaseImageUri
      .replace(/:SPECIES_ID/, speciesId)
      .replace(/:PHOTO_DATA_ID/, photoDataId);
    return `${endpoint}${imagePath}`;
  };

  return (
    <SpeciesListComponent
      list={species}
      hasMore={hasMoreSpecies}
      isLoading={isLoading}
      selectedLetter={params?.letter?.toUpperCase() ?? "A"}
      baseUrl={APP_ROUTES.speciesList}
      onGetUrl={getSpeciesUrl}
      onImgUri={handleImageUrl}
      // onClickSpecies={handleClickSpecies}
      onLoadMore={handleLoadMore}
      onFilterList={debounce(handleFilterList, 500)}
    />
  );
}

const mapStateToProps = (state) => {
  const selectIsLoading = makeSelectIsLoading();

  return {
    isLoading: selectIsLoading(state),
    species: speciesList(state),
    hasMoreSpecies: selectSpeciesListHasMore(state),
  };
};

export default connect(mapStateToProps, {
  onGetPage: getSpeciesPage,
})(SpeciesList);
