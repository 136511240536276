import React from 'react'
import PropTypes from 'prop-types'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(0),
    color: grey[500]
  }
}))

export default function LoadingIndicator (props) {
  const classes = useStyles()

  return <CircularProgress className={classes.progress} {...props} />
}

LoadingIndicator.propTypes = {
  size: PropTypes.number || PropTypes.string
}
