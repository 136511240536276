import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Grid, Box, Typography, Divider } from "@material-ui/core";
import { Link } from "react-router-dom";
import Interweave from "interweave";
import Gallery from "../Gallery";
import SpeciesList from "../Species/SpeciesListTable";
import Loading from "../LoadingIndicator";
import "react-image-gallery/styles/css/image-gallery.css";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.common.white,
  },
  list: {
    listStyleType: "none",
    padding: 0,
    marginTop: 0,
  },
  itemRoot: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "no-wrap",
  },
  label: {
    display: "inline-block",
    width: "20%",
    fontSize: "1.1em",
    fontWeight: 600,
    color: "#ff8000",
  },
  itemData: {
    paddingmarginTop: 4,
    paddingBottom: 4,
    paddingLeft: 4,
    width: "80%",
    color: "#cccc00",
  },
  itemEditBtnRoot: {
    position: "absolute",
    right: 0,
    top: 0,
  },
  button: {
    padding: "0 8px",
  },
  editIcon: {
    color: theme.palette.common.white,
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },
  imageMainContainer: {
    border: "1px solid red",
    height: 450,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    position: "relative",
    "&:hover div": {
      visibility: "visible",
      opacity: 1,
    },
  },
  imageThumbContainer: {
    border: "1px solid #2a2a2a",
    maxWidth: 300,
    height: 200,
    position: "relative",
    "&:hover div": {
      visibility: "visible",
      opacity: 1,
    },
  },
  imageThumb: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    "&:hover": {
      cursor: "pointer",
    },
  },
  greyText: {
    color: "#b3b3b3",
  },
  navLink: {
    textDecoration: "none",
    "&:hover": {
      color: "#eee",
    },
  },
  divider: {
    backgroundColor: "rgba(255, 255, 255, 0.2)",
  },
  relatedSpeciesHeading: {
    color: theme.palette.text.secondary,
    fontSize: "1.2em",
  },
  genusNavLink: {
    fontWeight: "bold",
    color: theme.palette.text.secondary,
    textDecoration: "none",
    "&:hover": {
      color: theme.palette.text.primary,
    },
  },
  speciesHeading: {
    color: theme.palette.text.secondary,
    fontSize: "2em",
  },
  italics: {
    fontStyle: "italic !important",
  },
}));

function Species({
  species,
  relatedSpeciesList,
  isLoading,
  isLoadingRelatedSpeciesList,
  onImageUri,
  onGetGenusLink,
  onLinkToSpecies,
  onLinkToSpeciesImage,
}) {
  const classes = useStyles();

  const fields = [
    // {
    //   id: 'species',
    //   label: 'Species',
    //   data: species?.species ?? null
    // },
    // {
    //   id: 'genusId',
    //   label: 'Genus',
    //   data: species?.genus?.genus ?? null
    // },
    // {
    //   id: "author",
    //   label: "Author",
    //   data: species?.author ?? null,
    // },
    // {
    //   id: "author1",
    //   label: "Author 1",
    //   data: species?.author1 ?? null,
    // },
    // {
    //   id: "taxon1",
    //   label: "Taxon 1",
    //   data: species?.taxon1 ?? null,
    // },
    // {
    //   id: "subtax1",
    //   label: "Sub Taxon 1",
    //   data: species?.subtax1 ?? null,
    // },
    // {
    //   id: "author2",
    //   label: "Author 2",
    //   data: species?.author2 ?? null,
    // },
    // {
    //   id: "taxon2",
    //   label: "Taxon 2",
    //   data: species?.taxon2 ?? null,
    // },
    // {
    //   id: "subtax2",
    //   label: "Sub Taxon 2",
    //   data: species?.subtax2 ?? null,
    // },
    {
      id: "publicationsp",
      label: "Publication",
      data: species?.publicationsp ?? null,
    },
    {
      id: "pubyrsp",
      label: "Year",
      data: species?.pubyrsp ?? null,
    },
    {
      id: "synonym",
      label: "Synonym",
      data: species?.synonym ?? null,
    },
    {
      id: "commonName",
      label: "Common Name",
      data: species?.commonName ?? null,
    },
    {
      id: "section",
      label: "Section",
      data: species?.section ?? null,
    },
    {
      id: "subsection",
      label: "Sub Section",
      data: species?.subsection ?? null,
    },
    {
      id: "distributionsp",
      label: "Distribution",
      data: species?.distributionsp ?? null,
    },
    {
      id: "habitat",
      label: "Habitat",
      data: species?.habitat ?? null,
    },
    {
      id: "season",
      label: "Season",
      data: species?.season ?? null,
    },
    {
      id: "scent",
      label: "Scent",
      data: species?.scent ?? null,
    },
    {
      id: "characteristicsp",
      label: "Characteristics",
      data: species?.characteristicsp ?? null,
    },
    {
      id: "capsule",
      label: "Capsule",
      data: species?.capsule ?? null,
    },
    {
      id: "similarSpecies",
      label: "Similar Species",
      data: species?.similarSpecies ?? null,
    },
    {
      id: "notes",
      label: "Notes",
      data: species?.notes ?? null,
    },
    {
      id: "referencesp",
      label: "References",
      data: species?.referencesp ?? null,
    },
  ];

  const makeName = () => {
    if (!species) return "";

    const { websiteFormalName } = species;

    return websiteFormalName || "Unknown Species";
  };

  let speciesImages = [];

  if (!!species?.websiteFeaturePhotoId && !!species?.photoData) {
    const featuredImgData = species.photoData.find(
      (imgData) => imgData.id === species.websiteFeaturePhotoId
    );
    const _speciesImages = species.photoData.filter(
      (imgData) => imgData.id !== species.websiteFeaturePhotoId
    );

    speciesImages = featuredImgData
      ? [featuredImgData, ..._speciesImages]
      : [..._speciesImages];
  } else {
    speciesImages = species?.photoData ?? [];
  }

  // Request from Roger to always make these items italics
  const italicItemIds = {
    section: true,
    subsection: true,
  };

  return (
    <Box my={1}>
      <Grid container spacing={2} className={classes.root}>
        {isLoading && (
          <Grid item xs={12}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Loading size={24} />
            </Box>
          </Grid>
        )}
        {!isLoading && species !== null && (
          <>
            <Grid item xs={12}>
              <Typography
                component="h2"
                variant="h4"
                className={classes.speciesHeading}
                style={{ fontSize: 20 }}
              >
                <Interweave content={String(makeName())} />
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Gallery
                images={speciesImages}
                species={species.speciesName}
                onImageUri={onImageUri}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Divider style={{ backgroundColor: "#d5d5d5" }} />
              <Box my={1} />
              <ul className={classes.list}>
                {fields.map(({ id, label, data }) =>
                  data ? (
                    <li key={id} className={classes.listItem}>
                      <div className={classes.itemRoot}>
                        <span className={classes.label}>{label}:</span>
                        <div className={classes.itemData}>
                          <span
                            className={clsx(
                              italicItemIds[id] && classes.italics
                            )}
                          >
                            <Interweave content={String(data)} />
                          </span>
                        </div>
                      </div>
                    </li>
                  ) : null
                )}
              </ul>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Typography
            component="h2"
            variant="h6"
            className={classes.relatedSpeciesHeading}
          >
            {Boolean(species?.genus?.genusName) && (
              <Link className={classes.genusNavLink} to={onGetGenusLink()}>
                <em>{species?.genus?.genusName}</em>{" "}
              </Link>
            )}
            Species List
          </Typography>
          <SpeciesList
            genusName={species?.genus?.genusName}
            speciesList={relatedSpeciesList}
            filterPlaceholder="Filter by Species name"
            isLoading={isLoadingRelatedSpeciesList}
            linkToSpecies={onLinkToSpecies}
            linkToSpeciesImage={onLinkToSpeciesImage}
            tableColumns={[
              { id: "species", label: "Species Name" },
              { id: "publicationsp", label: "Publication" },
              { id: "pubyrsp", label: "Year" },
              { id: "distributionsp", label: "Distribution" },
              // { id: "characteristicsp", label: "Characteristics" },
              // { id: "referencesp", label: "References" },
            ]}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Species;
