// import React from "react";
// import CloseButton from "../../../components/CloseButton";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { orchidsApi } from "../../api";
// import { enqueueNotification, closeNotification } from "./noistack";

const initialState = {
  list: [],
  hasMore: false,
  byGenusList: [],
  selected: null,
  api: {
    currentRequestId: undefined,
    loading: "idle",
    error: null,
  },
  byGenusListApi: {
    currentRequestId: undefined,
    loading: "idle",
    error: null,
  },
};

// Disabled showing notifcations in public website
// const notifError =
//   (message, variant = "error") =>
//   (dispatch) => {
//     dispatch(
//       enqueueNotification({
//         message: message,
//         options: {
//           variant,
//           action: (key) => (
//             <CloseButton onClick={() => dispatch(closeNotification(key))} />
//           ),
//         },
//       })
//     );
//   };

export const getGenusSpeciesList = createAsyncThunk(
  "species/getGenusSpeciesList",
  async ({ genusId }, { getState, requestId, rejectWithValue, dispatch }) => {
    const {
      byGenusListApi: { currentRequestId, loading },
    } = getState().species;

    const makeRequest = async () => {
      try {
        const response = await orchidsApi.getGenusSpeciesList({ genusId });
        return response;
      } catch (err) {
        throw new Error(err.message);
      }
    };

    try {
      if (
        loading !== "getGenusSpeciesList/pending" ||
        requestId !== currentRequestId
      ) {
        return;
      }
      const data = await makeRequest();
      return data;
    } catch (err) {
      // dispatch(notifError(err.message || "Failed to get data"));
      return rejectWithValue(err.message);
    }
  }
);

export const getSpeciesPage = createAsyncThunk(
  "species/getSpeciesPage",
  async (
    { page, limit, query, startLetter },
    { getState, requestId, rejectWithValue, dispatch }
  ) => {
    const {
      api: { currentRequestId, loading },
    } = getState().species;

    const makeRequest = async () => {
      try {
        const response = await orchidsApi.getSpeciesPage({
          page,
          limit,
          query,
          startLetter,
        });

        return response;
      } catch (err) {
        throw new Error(err.message);
      }
    };

    try {
      if (
        loading !== "getSpeciesPage/pending" ||
        requestId !== currentRequestId
      ) {
        return;
      }
      const data = await makeRequest();
      return data;
    } catch (err) {
      // dispatch(notifError(err.message || "Failed to get data"));
      return rejectWithValue(err.message);
    }
  }
);

export const getSpecies = createAsyncThunk(
  "species/getSpecies",
  async (
    { speciesSlug },
    { getState, requestId, rejectWithValue, dispatch }
  ) => {
    const {
      api: { currentRequestId, loading },
    } = getState().species;

    const makeRequest = async () => {
      try {
        const response = await orchidsApi.getSpecies({ speciesSlug });
        return response;
      } catch (err) {
        throw new Error(err.message);
      }
    };

    try {
      if (loading !== "getSpecies/pending" || requestId !== currentRequestId) {
        return;
      }
      const data = await makeRequest();

      return data;
    } catch (err) {
      // dispatch(notifError(err.message || "Failed to get data"));
      return rejectWithValue(err.message);
    }
  }
);

const speciesSlice = createSlice({
  name: "species",
  initialState,
  reducers: {
    clearByGenusList(state) {
      state.byGenusList = [];
    },
  },
  extraReducers: {
    /* Get Species List */
    [getSpeciesPage.pending]: (state, action) => {
      if (state.api.loading === "idle") {
        state.api.loading = "getSpeciesPage/pending";
        state.api.currentRequestId = action.meta.requestId;
      }
    },
    [getSpeciesPage.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.api.loading === "getSpeciesPage/pending" &&
        state.api.currentRequestId === requestId
      ) {
        state.api.loading = "idle";
        state.api.currentRequestId = undefined;

        const { loadMore } = action.meta.arg;

        if (loadMore) {
          state.list = [...state.list, ...action.payload.results];
        } else {
          state.list = [...action.payload.results];
        }

        state.hasMore = !!action.payload.nextPage;
      }
    },
    [getSpeciesPage.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.api.loading === "getSpeciesPage/pending" &&
        state.api.currentRequestId === requestId
      ) {
        state.api.loading = "idle";
        state.api.error = action.error;
        state.api.currentRequestId = undefined;
      }
    },

    /* Get Individual Species */
    [getSpecies.pending]: (state, action) => {
      if (state.api.loading === "idle") {
        state.api.loading = "getSpecies/pending";
        state.api.currentRequestId = action.meta.requestId;
        state.api.error = null;
        state.selected = null;
      }
    },
    [getSpecies.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.api.loading === "getSpecies/pending" &&
        state.api.currentRequestId === requestId
      ) {
        state.api.loading = "idle";
        state.api.currentRequestId = undefined;
        state.api.error = null;
        state.selected = { ...action.payload };
      }
    },
    [getSpecies.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.api.loading === "getSpecies/pending" &&
        state.api.currentRequestId === requestId
      ) {
        state.api.loading = "idle";
        state.api.error =
          action.payload === "NOT_FOUND" ? "NOT_FOUND" : action.error;
        state.api.currentRequestId = undefined;
        state.selected = null;
      }
    },

    /* Get Genus Species List */
    [getGenusSpeciesList.pending]: (state, action) => {
      if (state.byGenusListApi.loading === "idle") {
        state.byGenusListApi.loading = "getGenusSpeciesList/pending";
        state.byGenusListApi.currentRequestId = action.meta.requestId;
        state.byGenusList = [];
      }
    },
    [getGenusSpeciesList.fulfilled]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.byGenusListApi.loading === "getGenusSpeciesList/pending" &&
        state.byGenusListApi.currentRequestId === requestId
      ) {
        state.byGenusListApi.loading = "idle";
        state.byGenusListApi.currentRequestId = undefined;
        state.byGenusList = action.payload?.specieslist ?? [];
      }
    },
    [getGenusSpeciesList.rejected]: (state, action) => {
      const { requestId } = action.meta;
      if (
        state.byGenusListApi.loading === "getGenusSpeciesList/pending" &&
        state.byGenusListApi.currentRequestId === requestId
      ) {
        state.byGenusListApi.loading = "idle";
        state.byGenusListApi.error = action.error;
        state.byGenusListApi.currentRequestId = undefined;
        state.byGenusList = [];
      }
    },
  },
});

export const { clearByGenusList } = speciesSlice.actions;

export default speciesSlice.reducer;
