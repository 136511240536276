import React from "react";
import sort from "fast-sort";
import GenusItem from "../../components/Genera/GenusItem";
import { APP_ROUTES } from "../../lib/constants/app-routes";

const { REACT_APP_API_HOST } = process.env;

function Genus({ genus, speciesList, isLoading, isLoadingSpeciesList }) {
  const handleLinkToSpecies = (speciesSlug, speciesName) => {
    return {
      pathname: `${APP_ROUTES.species}/${speciesSlug}`,
      state: { speciesName },
    };
  };

  const handleLinkToImage = (imageUri) => {
    return `${REACT_APP_API_HOST + "/public/" + imageUri}`;
  };

  const handleLinkToSpeciesImage = (
    speciesId,
    websiteFeaturePhotoId,
    photoData
  ) => {
    if (!Array.isArray(photoData)) return null;
    if (!websiteFeaturePhotoId) return null;

    const _photoData = photoData.find((d) => d.id === websiteFeaturePhotoId);

    if (!_photoData) return null;

    const { websitePhotoUri, id } = _photoData;

    if (!websitePhotoUri) return null;

    return `${REACT_APP_API_HOST}/public/species/${speciesId}/${id}${websitePhotoUri}`;
  };

  const _speciesList =
    Array.isArray(speciesList) && genus
      ? sort([...speciesList]).asc((sp) => {
          const { websiteFormalName } = sp;
          return String(websiteFormalName).replace(/<[^>]*>?/gm, "");
        })
      : [];

  return (
    <GenusItem
      genus={genus}
      isLoading={isLoading}
      isLoadingSpeciesList={isLoadingSpeciesList || isLoading}
      speciesList={_speciesList}
      linkToImage={handleLinkToImage}
      onLinkToSpecies={handleLinkToSpecies}
      onLinkToSpeciesImage={handleLinkToSpeciesImage}
    />
  );
}

export default Genus;
