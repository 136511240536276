import React, { Suspense } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LazyLoad from "react-lazyload";
import SuspenseImage, {
  SuspenseOrchidCardLoading as SuspenseLoading,
} from "../containers/SuspenseOrchidCardImage";
import Interweave from "interweave";

function CenterContent({ children }) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      {children}
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    background: theme.palette.background.default,
    "&:focus": {
      outline: "none",
    },
  },
  content: {
    borderBottom: "1px solid #2a2a2a",
    borderRight: "1px solid #2a2a2a",
    borderLeft: "1px solid #2a2a2a",
    borderRadius: 4,
  },
  media: {
    height: 140,
  },
}));

export default function OrchidCard({ title, image, url }) {
  const classes = useStyles();

  return (
    <Card component="a" className={classes.root}>
      <a href={url}>
        <LazyLoad height={140} once>
          {/* <CardMedia className={classes.media} image={image} title={title} /> */}
          <Suspense fallback={<SuspenseLoading />}>
            <CenterContent>
              <SuspenseImage
                src={image}
                className={classes.media}
                alt={title}
              />
            </CenterContent>
          </Suspense>
        </LazyLoad>
        <CardContent className={classes.content}>
          <Typography
            gutterBottom
            variant="h6"
            component="h3"
            style={{ fontSize: 14, textAlign: "center" }}
          >
            {typeof title === "object" ? (
              title
            ) : (
              <Interweave content={String(title)} />
            )}
          </Typography>
        </CardContent>
      </a>
    </Card>
  );
}
