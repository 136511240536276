import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";

const imgCache = {
  __cache: {},
  read(src) {
    if (!src) {
      return;
    }

    if (!this.__cache[src]) {
      this.__cache[src] = new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          this.__cache[src] = true;
          resolve(this.__cache[src]);
        };
        img.src = src;
        setTimeout(() => resolve({}), 7000);
      }).then((img) => {
        this.__cache[src] = true;
      });
    }

    if (this.__cache[src] instanceof Promise) {
      throw this.__cache[src];
    }
    return this.__cache[src];
  },
  clearImg: (src) => {
    delete this.__cache[src];
  },
};

export const SuspenseOrchidCardLoading = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{
        borderLeft: "1px solid #2a2a2a",
        borderRight: "1px solid #2a2a2a",
        borderTop: "1px solid #2a2a2a",
        borderRadius: "4px",
        height: 140,
        color: "white",
      }}
    >
      <CircularProgress size={16} color="inherit" />
    </Box>
  );
};

const SuspenseOrchidCardImage = ({ src, alt, ...rest }) => {
  imgCache.read(src);

  return <img alt={alt} src={src} {...rest} />;
};

export default SuspenseOrchidCardImage;
