import React from "react";
import isEqual from "react-fast-compare";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import LetterPagination from "../LetterPagination";
import OrchidCard from "../OrchidCard";
import LoadingIndicator from "../LoadingIndicator";
import placeholder from "../../_assets/images/placeholder.png";

function SpeciesList({
  list,
  hasMore,
  isLoading,
  onImgUri,
  selectedLetter,
  baseUrl,
  onGetUrl,
  onFilterList,
  onLoadMore,
}) {
  const handleFilter = (event) => {
    const { value } = event.target;
    onFilterList(value);
  };

  const getFeaturedImageUri = (photoData) => {
    if (photoData?.length > 0) {
      const { speciesId, id, websitePhotoUri } = photoData[0];

      if (!websitePhotoUri) return placeholder;

      return onImgUri(speciesId, id, websitePhotoUri);
    } else {
      return placeholder;
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LetterPagination
          selected={selectedLetter}
          disabled={isLoading}
          baseUrl={baseUrl}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          disabled={isLoading}
          id="standard-full-width"
          style={{
            backgroundColor: "#fff",
            borderRadius: 5,
            color: "red",
            borderWidth: 0,
          }}
          placeholder="Filter by Species name"
          fullWidth
          margin="dense"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          onChange={handleFilter}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          {list.map(
            ({
              id,
              websiteSlug,
              speciesName,
              photoData,
              websiteFormalName,
            }) => {
              return (
                <Grid item xs={12} sm={3} md={2} key={id}>
                  <OrchidCard
                    title={websiteFormalName}
                    image={getFeaturedImageUri(photoData)}
                    url={onGetUrl(websiteSlug)}
                  />
                </Grid>
              );
            }
          )}
          {list?.length === 0 && !isLoading && (
            <Box width="100%" textAlign="center">
              <Typography
                component="span"
                style={{ fontWeight: "bold", fontSize: "1.2em" }}
              >
                No species found
              </Typography>
            </Box>
          )}
        </Grid>
        {isLoading && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            my={2}
          >
            <LoadingIndicator size={22} />
          </Box>
        )}
        {hasMore && (
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            mt={2}
          >
            <Button onClick={onLoadMore}>Load More</Button>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

SpeciesList.defaultProps = {
  list: [],
  isLoading: false,
  onChangeLetter: null,
};

export default React.memo(SpeciesList, isEqual);
