import { createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import rootReducer from './reducers'

const persistConfig = {
  key: 'yonggee-public',
  storage,
  throttle: 2000,
  whitelist: []
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const configureStore = () => {
  const middleware = [thunk]

  if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger())
  }

  const store = createStore(persistedReducer, applyMiddleware(...middleware))
  const persistor = persistStore(store)
  return { store, persistor }
}

export default configureStore
