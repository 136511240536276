import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import { PersistorContext } from './withReduxPersistor'
import configureStore from '../lib/store/configureStore'

const { store, persistor } = configureStore()

export const reduxPersistor = persistor

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <PersistorContext.Provider value={persistor}>
        <Router>
          <App />
        </Router>
      </PersistorContext.Provider>
    </PersistGate>
  </Provider>
)

export default Root
