import React from "react";
import Interweave from "interweave";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { SRLWrapper } from "simple-react-lightbox";
import { withStyles } from "@material-ui/core/styles";
import placeholderImage from "../_assets/images/placeholder.png";

const browser = navigator.userAgent;
const isFirefox = browser.indexOf("Firefox") > -1;

const styles = (theme) => ({
  gallery: {
    "& .splide--nav>.splide__track>.splide__list>.splide__slide.is-active": {
      border: "1px solid #ff8000",
      borderRadius: 4,
    },
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  imageMainContainer: {
    overFlow: "scroll",
    width: "99.5%",
    height: 330,
    position: "relative",
    "&:hover div": {
      visibility: "visible",
      opacity: 1,
    },
  },
  imageThumbContainer: {
    border: "1px solid #2a2a2a",
    maxWidth: 300,
    height: 200,
    position: "relative",
    "&:hover div": {
      visibility: "visible",
      opacity: 1,
    },
  },
  imageThumb: {
    zIndex: 10,
    width: "100%",
    height: "100%",
    objectFit: "contain",
    "&:hover": {
      cursor: "pointer",
    },
  },
  infoContainer: {
    color: "#b9b9b9",
    textAlign: "center",
    height: 70,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 0,
    paddingBottom: 5,
    overflow: "scroll",
    borderBottom: "1px solid #2a2a2a",
    width: "99.5%",
  },
});

class ThumbnailSlider extends React.Component {
  constructor(props) {
    super(props);

    this.primaryRef = React.createRef();
    this.secondaryRef = React.createRef();
  }

  componentDidMount() {
    const { images } = this.props;
    if (images.length) {
      // Set the sync target right after the component is mounted.
      this.primaryRef.current.sync(this.secondaryRef.current.splide);
    }
  }

  render() {
    const { images, species, classes, onImageUri } = this.props;

    const primaryOptions = {
      type: "loop",
      perPage: 1,
      perMove: 1,
      fixedHeight: 410,
      gap: "1rem",
      pagination: false,
    };

    const secondaryOptions = {
      type: "slide",
      rewind: true,
      autowidth: true,
      gap: "1rem",
      pagination: false,
      fixedWidth: 110,
      fixedHeight: 70,
      cover: true,
      focus: "center",
      isNavigation: true,
      updateOnMove: true,
    };

    const srlOptions = {
      buttons: {
        showDownloadButton: false,
      },
      caption: {
        showCaption: false,
      },
      settings: {
        overlayColor: isFirefox ? "#000000" : "rgba(0,0,0,0.9)",
      },
      thumbnails: {
        showThumbnails: false,
      },
    };

    if (images.length === 0) {
      return (
        <div>
          <img
            src={placeholderImage}
            className={classes.image}
            alt="Orchid Placeholder"
          />
        </div>
      );
    }

    return (
      <div className={classes.gallery}>
        <SRLWrapper options={srlOptions}>
          <Splide options={primaryOptions} ref={this.primaryRef}>
            {images.map((imgData) => {
              const { speciesId, id, websitePhotoUri } = imgData;
              const imgUri = websitePhotoUri
                ? onImageUri(speciesId, id, websitePhotoUri)
                : placeholderImage;

              return (
                <SplideSlide key={id}>
                  <div className={classes.imageMainContainer}>
                    <a href={imgUri} data-attribute="SRL">
                      <img
                        src={imgUri}
                        alt={species}
                        className={classes.imageThumb}
                      />
                    </a>

                    <div className={classes.infoContainer}>
                      <span style={{ display: "block" }}>
                        <Interweave
                          content={[
                            { title: "Width", value: imgData.width },
                            { title: "Height", value: imgData.height },
                            { title: "Depth", value: imgData.depth },
                          ]
                            .filter((i) => !!i.value)
                            .map(
                              (i) =>
                                `${i.title}: <span style="color:#eee;">${i.value}mm</span>`
                            )
                            .join(" • ")}
                        />
                      </span>
                    </div>
                  </div>
                </SplideSlide>
              );
            })}
          </Splide>
        </SRLWrapper>

        <Splide options={secondaryOptions} ref={this.secondaryRef}>
          {images.map((imgData) => {
            const { speciesId, id, websitePhotoUri } = imgData;
            const imgUri = websitePhotoUri
              ? onImageUri(speciesId, id, websitePhotoUri)
              : placeholderImage;
            return (
              <SplideSlide key={id}>
                <img
                  src={imgUri}
                  alt={species}
                  className={classes.imageThumb}
                />
              </SplideSlide>
            );
          })}
        </Splide>
      </div>
    );
  }
}

export default withStyles(styles)(ThumbnailSlider);
