import React from "react";
import PropTypes from "prop-types";
import { useLocation, Link } from "react-router-dom";
import { Container, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";
import TopMenu from "./TopMenu";
import { APP_ROUTES } from "../lib/constants/app-routes";

function Header({ currentRoute }) {
  // const isListPage =
  //   currentRoute?.includes(APP_ROUTES.generaList) ||
  //   currentRoute?.includes(APP_ROUTES.speciesList);

  const menu = [
    // {
    //   to: APP_ROUTES.search,
    //   label: 'Search'
    // },
    {
      to: `${APP_ROUTES.generaList}`,
      label: "Genera",
    },
    {
      to: `${APP_ROUTES.speciesList}`,
      label: "Species",
    },
  ];

  return (
    <div className="header-sticky">
      <header>
        <div className="wrapper">
          <div className="top-left">
            <div className="logo">
              <Link to={APP_ROUTES.generaList} className="custom-logo-link">
                <img
                  width="268"
                  height="268"
                  src="/images/logo-gary.png"
                  className="custom-logo"
                  alt="Gary Yong Gee"
                  srcSet="/images/logo-gary-268w.png 268w, /images/logo-150w.png 150w"
                  sizes="(max-width: 268px) 100vw, 268px"
                />
              </Link>
            </div>
            <div className="menu-bar">
              <div className="menu-main-menu-container">
                <ul className="menu">
                  {menu.map((m) => (
                    <li
                      key={m.label}
                      className={currentRoute?.includes(m.to) ? "active" : ""}
                    >
                      <Link to={m.to}>{m.label}</Link>
                    </li>
                  ))}
                  <li>
                    <a href="https://yonggee.name/">Main Site</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="top-right" />
        </div>
      </header>
    </div>
  );
}

function PageTemplate({ children }) {
  const location = useLocation();
  return (
    <Container>
      <TopMenu />
      <Header currentRoute={location?.pathname || APP_ROUTES.search} />
      <Box mt={2}>{children}</Box>
      <Box my={8}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box>
            <a href="mailto:gary@yonggee.name">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    width: 40,
                    height: 40,
                    backgroundColor: "#2a2a2a",
                    borderRadius: 20,
                  }}
                  mr={0.5}
                >
                  <EmailIcon style={{ color: "#008000" }} />
                </Box>
                <Typography component="span" style={{ color: "#008000" }}>
                  gary@yonggee.name
                </Typography>
              </Box>
            </a>
          </Box>
          <Box width={50} />
          <Box>
            <a href="tel:+61733984222">
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    width: 40,
                    height: 40,
                    backgroundColor: "#2a2a2a",
                    borderRadius: 20,
                  }}
                  mr={0.5}
                >
                  <PhoneIcon style={{ color: "#008000" }} />
                </Box>
                <Typography component="span" style={{ color: "#008000" }}>
                  +61 (0)7 3398 4222
                </Typography>
              </Box>
            </a>
          </Box>
        </Box>
        <Box pt={2}>
          <Typography component="p" align="center" style={{ color: "#008000" }}>
            by ordinary mail to 1 Nepean Court, Carina, Queensland 4152,
            Australia
          </Typography>
        </Box>
        <Box pt={2}>
          <Typography component="p" align="center" style={{ color: "#008000" }}>
            &copy; Copyright {new Date().getFullYear()}. Gary Yong Gee
          </Typography>
        </Box>
      </Box>
    </Container>
  );
}

PageTemplate.propTypes = {
  children: PropTypes.node,
};

PageTemplate.defaultProps = {
  children: null,
};

export default PageTemplate;
