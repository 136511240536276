import { nonAuthRequest } from "./apiSetup";
import { API_ENDPOINTS } from "../../constants/api-endpoints";

/*
 * Orchids API: Genera
 ** */

// const delay = (ms) =>
//   new Promise((resolve) => setTimeout(() => resolve(true), ms));

export function getGeneraPage({ page, limit, query, startLetter }) {
  // /public/page/genera/:pageNo?limit=:limitNumber&startLetter=(yes)&query=:query(genusName)
  const endpoint = API_ENDPOINTS.generaPage
    .replace(/:PAGE_NUMBER/, page)
    .replace(/:LIMIT/, limit)
    .replace(/:START_LETTER/, startLetter ? `&startLetter=yes` : "")
    .replace(/:QUERY/, query ? `&query=${query}` : "");

  return nonAuthRequest({ method: "GET", endpoint })
    .then((response) => response)
    .catch((error) => {
      console.log("getGeneraPage error:", error.message);
      throw new Error(
        error.message || "There was a problem processing the request"
      );
    });
}

export function getGenus({ genusSlug }) {
  // /public/page/genus/:slug
  const endpoint = API_ENDPOINTS.genera.replace(/:GENUS_SLUG/, genusSlug);

  return nonAuthRequest({ method: "GET", endpoint })
    .then((response) => response)
    .catch((error) => {
      console.log("getGenus error:", error.message);
      throw new Error(
        error.message || "There was a problem processing the request"
      );
    });
}

export function getGenusSpeciesList({ genusId }) {
  // /public/page/genera/:genusId/specieslist
  const endpoint = API_ENDPOINTS.genusSpeciesList.replace(/:GENUS_ID/, genusId);

  return nonAuthRequest({ method: "GET", endpoint })
    .then((response) => response)
    .catch((error) => {
      console.log("getGenusSpeciesList error:", error.message);
      throw new Error(
        error.message || "There was a problem processing the request"
      );
    });
}

/*
 * Orchids API: Species
 ** */

export function getSpeciesPage({ page, limit, query, startLetter }) {
  // /public/page/species/:pageNo?limit=:limitNumber&startLetter=(yes)&query=:query(genusName)
  const endpoint = API_ENDPOINTS.speciesPage
    .replace(/:PAGE_NUMBER/, page)
    .replace(/:LIMIT/, limit)
    .replace(/:START_LETTER/, startLetter ? `&startLetter=yes` : "")
    .replace(/:QUERY/, query ? `&query=${query}` : "");

  return nonAuthRequest({ method: "GET", endpoint })
    .then((response) => response)
    .catch((error) => {
      console.log("getSpeciesPage error:", error.message);
      throw new Error(
        error.message || "There was a problem processing the request"
      );
    });
}

export function getSpecies({ speciesSlug }) {
  // /public/page/species/:slug
  const endpoint = API_ENDPOINTS.species.replace(/:SPECIES_SLUG/, speciesSlug);

  return nonAuthRequest({ method: "GET", endpoint })
    .then((response) => response)
    .catch((error) => {
      console.log("getSpecies error:", error.message);
      throw new Error(
        error.message || "There was a problem processing the request"
      );
    });
}
