import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const LETTERS = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

const useStyles = makeStyles(() => ({
  letter: {
    "&:hover": {
      backgroundColor: "#af3476",
    },
  },
}));

function LetterList({ disabled, baseUrl, selected }) {
  const styles = useStyles();

  return (
    <Box style={{ overflowX: "scroll" }}>
      <List
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 0,
          height: "50px",
        }}
      >
        {LETTERS.map((letter, index) => (
          <ListItem
            key={index}
            style={{
              padding: 0,
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              margin: "0px 5px",
            }}
          >
            <Link to={`${baseUrl}/${letter}`}>
              <Box
                className={styles.letter}
                style={{
                  backgroundColor:
                    letter?.toLowerCase() === selected?.toLowerCase()
                      ? "#af3476"
                      : undefined,
                  // border: "1px solid transparent",
                  borderRadius: "15px",
                  width: "30px",
                  height: "30px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  // boxShadow: "0 0 1px 0px white inset, 0 0 1px 0px white",
                }}
              >
                <Typography variant="body2">{letter}</Typography>
              </Box>
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default LetterList;
