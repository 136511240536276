import { createSelector } from "reselect";

const speciesSelector = (state) => state.species;

export const makeSelectIsLoading = () =>
  createSelector(
    [speciesSelector],
    (species) =>
      species.api.loading === "getSpeciesPage/pending" ||
      species.api.loading === "getSpecies/pending"
  );

export const makeSelectIsLoadingGenusSpeciesList = () =>
  createSelector(
    [speciesSelector],
    (species) =>
      species.byGenusListApi.loading === "getGenusSpeciesList/pending"
  );

export const makeSelectGenusSpeciesList = () =>
  createSelector([speciesSelector], (species) => species.byGenusList);

export const speciesList = createSelector(
  [speciesSelector],
  (species) => species.list
);

export const selectSpeciesListHasMore = createSelector(
  [speciesSelector],
  (species) => species.hasMore
);

export const makeSelectedSpecies = () =>
  createSelector([speciesSelector], (species) => species.selected);

export const makeSelectSpeciesApiError = () =>
  createSelector([speciesSelector], (species) => species.api.error);
