import React from 'react'
import { Grid } from '@material-ui/core'
import PageTemplate from '../components/PageTemplate'
import GeneraList from '../containers/Genera/List'

const GeneraListPage = () => {
  return (
    <PageTemplate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GeneraList />
        </Grid>
      </Grid>
    </PageTemplate>
  )
}

export default GeneraListPage
