import React from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
// import { makeStyles } from '@material-ui/styles'
import { Grid } from "@material-ui/core";
import PageTemplate from "../components/PageTemplate";
import Species from "../containers/Species/Species";
import {
  makeSelectedSpecies,
  makeSelectIsLoading,
  makeSelectIsLoadingGenusSpeciesList,
  makeSelectGenusSpeciesList,
  makeSelectSpeciesApiError,
} from "../lib/store/selectors/species";
import { getSpecies, getGenusSpeciesList } from "../lib/store/reducers/species";
import {
  makeSelectIsLoading as makeSelectIsLoadingGenera,
  makeSelectGeneraList,
} from "../lib/store/selectors/genera";
import { getGenera } from "../lib/store/reducers/genera";

// const usePageStyles = makeStyles(theme => ({
//   searchContainer: {
//     width: 250,
//     ['@media only screen and (min-width:400px)']: { // eslint-disable-line no-useless-computed-key
//       width: 350
//     },
//     ['@media only screen and (min-width:500px)']: { // eslint-disable-line no-useless-computed-key
//       width: 400
//     },
//     ['@media only screen and (min-width:768px)']: { // eslint-disable-line no-useless-computed-key
//       width: 500
//     }
//   },
//   heading: {
//     fontSize: '1.5em',
//     color: theme.palette.text.secondary,
//     ['@media only screen and (min-width:400px)']: { // eslint-disable-line no-useless-computed-key
//       fontSize: '2em'
//     }
//   }
// }))

const SpeciesPage = ({
  selectedSpecies,
  speciesApiError,
  generaList,
  relatedSpeciesList,
  getSpecies,
  getGenera,
  getGenusSpeciesList,
  isLoading,
  isLoadingRelatedSpeciesList,
  isLoadingGenera,
}) => {
  // const classes = usePageStyles()

  const { speciesSlug } = useParams();

  const loadSpecies = React.useCallback(() => {
    getSpecies({ speciesSlug });
  }, [getSpecies, speciesSlug]);

  React.useEffect(() => {
    loadSpecies();
  }, [loadSpecies]);

  const loadGenera = React.useCallback(() => {
    getGenera();
  }, [getGenera]);

  React.useEffect(() => {
    loadGenera();
  }, [loadGenera]);

  const loadRelatedSpecies = React.useCallback(() => {
    if (selectedSpecies?.genus?.id) {
      getGenusSpeciesList({ genusId: selectedSpecies?.genus?.id });
    }
  }, [getGenusSpeciesList, selectedSpecies]);

  React.useEffect(() => {
    loadRelatedSpecies();
  }, [loadRelatedSpecies]);

  return (
    <PageTemplate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Species
            species={selectedSpecies}
            speciesApiError={speciesApiError}
            relatedSpeciesList={relatedSpeciesList}
            generaList={generaList}
            isLoading={isLoading}
            isLoadingRelatedSpeciesList={isLoadingRelatedSpeciesList}
            isLoadingGenera={isLoadingGenera}
          />
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

SpeciesPage.defaultProps = {};

const mapStateToProps = (state) => {
  const selectIsLoadingGenera = makeSelectIsLoadingGenera();
  const selectGeneraList = makeSelectGeneraList();
  const selectIsLoadingRelatedSpeciesList =
    makeSelectIsLoadingGenusSpeciesList();
  const selectIsLoading = makeSelectIsLoading();
  const selectedSpecies = makeSelectedSpecies();
  const selectGenusSpeciesList = makeSelectGenusSpeciesList();
  const selectSpeciesApiError = makeSelectSpeciesApiError();

  return {
    isLoading: selectIsLoading(state),
    isLoadingRelatedSpeciesList: selectIsLoadingRelatedSpeciesList(state),
    isLoadingGenera: selectIsLoadingGenera(state),
    selectedSpecies: selectedSpecies(state),
    speciesApiError: selectSpeciesApiError(state),
    generaList: selectGeneraList(state),
    relatedSpeciesList: selectGenusSpeciesList(state),
  };
};

export default connect(mapStateToProps, {
  getSpecies,
  getGenera,
  getGenusSpeciesList,
})(SpeciesPage);
