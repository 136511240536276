import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import CssBaseline from "@material-ui/core/CssBaseline";
import withWidth from "@material-ui/core/withWidth";
import { ThemeProvider } from "@material-ui/styles";
import SimpleReactLightbox from "simple-react-lightbox";
import theme from "../lib/theme";
import { APP_ROUTES } from "../lib/constants/app-routes";
import Notifier from "./Notifier";
/* Pages */
import GeneraListPage from "../app-pages/GeneraListPage";
import SpeciesListPage from "../app-pages/SpeciesListPage";
import GenusPage from "../app-pages/GenusPage";
import SpeciesPage from "../app-pages/SpeciesPage";
// import SearchPage from '../app-pages/SearchPage'
import FourOhFour from "../app-pages/FourOhFour";

const App = ({ width }) => {
  return (
    <SimpleReactLightbox>
      <SnackbarProvider
        preventDuplicate
        maxSnack={4}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Switch>
            <Redirect exact path="/" to={`${APP_ROUTES.generaList}/A`} />
            <Redirect
              exact
              path="/species"
              to={`${APP_ROUTES.speciesList}/A`}
            />
            <Redirect
              exact
              path={APP_ROUTES.speciesList}
              to={`${APP_ROUTES.speciesList}/A`}
            />
            <Redirect
              exact
              path={APP_ROUTES.generaList}
              to={`${APP_ROUTES.generaList}/A`}
            />
            <Redirect exact path="/genera" to={`${APP_ROUTES.generaList}/A`} />
            <Redirect exact path="/home" to={`${APP_ROUTES.generaList}/A`} />
            {/* <Route path={APP_ROUTES.search} exact component={SearchPage} /> */}
            <Route
              path={`${APP_ROUTES.species}/:speciesSlug`}
              component={SpeciesPage}
            />
            <Route
              path={`${APP_ROUTES.genera}/:genusSlug`}
              component={GenusPage}
            />
            <Route
              path={`${APP_ROUTES.speciesList}/:letter`}
              component={SpeciesListPage}
            />
            <Route
              path={`${APP_ROUTES.generaList}/:letter`}
              component={GeneraListPage}
            />
            <Route path="*" exact component={FourOhFour} />
          </Switch>
          <Notifier />
        </ThemeProvider>
      </SnackbarProvider>
    </SimpleReactLightbox>
  );
};

export default withWidth()(App);
