import React from "react";
import PropTypes from "prop-types";
import Interweave from "interweave";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import isPropsEqual from "react-fast-compare";
import TableCellComponent from "@material-ui/core/TableCell";
import TableRowComponent from "@material-ui/core/TableRow";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Table from "../TableTemplateFilter";
import placeholderImage from "../../_assets/images/placeholder.png";

const TableCell = React.memo(
  (props) => <TableCellComponent {...props} />,
  isPropsEqual
);

const TableRow = React.memo(
  (props) => <TableRowComponent {...props} />,
  isPropsEqual
);

const useStyles = makeStyles((theme) => ({
  bold: {
    fontWeight: "bold",
  },
  noUnderline: {
    textDecoration: "none",
  },
  imageContainer: {
    width: 50,
    height: 50,
    marginRight: 10,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    cursor: "pointer",
  },
}));

function SpeciesListTable({
  genusName,
  speciesList,
  isLoading,
  linkToSpecies,
  linkToGenus,
  linkToSpeciesImage,
  tableColumns,
  filterPlaceholder,
  genus,
}) {
  const classes = useStyles();

  const handleGetSpeciesUrl = (speciesSlug, speciesName) => {
    return linkToSpecies(speciesSlug, speciesName);
  };

  const renderImage = (speciesId, websiteFeaturePhotoId, photoData) => {
    const imgUri = linkToSpeciesImage(
      speciesId,
      websiteFeaturePhotoId,
      photoData
    );

    return (
      <div className={classes.imageContainer}>
        <img
          src={!!websiteFeaturePhotoId && !!imgUri ? imgUri : placeholderImage}
          className={classes.image}
          alt="img"
          data-attribute="SRL"
        />
      </div>
    );
  };

  const renderRow = ({ rowData }) => {
    const {
      id: speciesId,
      websiteSlug,
      speciesName,
      websiteFeaturePhotoId,
      photoData,
      publicationsp,
      pubyrsp,
      // characteristicsp,
      distributionsp,
      // referencesp,
      websiteFormalName,
    } = rowData;
    return (
      <TableRow>
        <TableCell>
          <Tooltip title="Click to view" placement="right">
            <Link
              to={handleGetSpeciesUrl(websiteSlug, speciesName)}
              className={classes.noUnderline}
            >
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                {renderImage(speciesId, websiteFeaturePhotoId, photoData)}
                <Typography
                  component="span"
                  variant="body2"
                  color="textSecondary"
                >
                  <Interweave content={String(websiteFormalName)} />
                </Typography>
              </Box>
            </Link>
          </Tooltip>
        </TableCell>
        <TableCell>
          {!!publicationsp && (
            <Typography component="span" variant="body2" color="textSecondary">
              <Interweave content={String(publicationsp)} />
            </Typography>
          )}
        </TableCell>
        <TableCell>
          {!!pubyrsp && (
            <Typography component="span" variant="body2" color="textSecondary">
              <Interweave content={String(pubyrsp)} />
            </Typography>
          )}
        </TableCell>
        <TableCell>
          {!!distributionsp && (
            <Typography component="span" variant="body2" color="textSecondary">
              <Interweave content={String(distributionsp)} />
            </Typography>
          )}
        </TableCell>
        {/* <TableCell>
          {!!characteristicsp && (
            <Typography component="span" variant="body2" color="textSecondary">
              <Interweave content={String(characteristicsp)} />
            </Typography>
          )}
        </TableCell> */}
        {/* <TableCell>
          {!!referencesp && (
            <Typography component="span" variant="body2" color="textSecondary">
              <Interweave content={String(referencesp)} />
            </Typography>
          )}
        </TableCell> */}
      </TableRow>
    );
  };

  const tableHeaders = [...tableColumns];

  return (
    <Grid container spacing={2} style={{ paddingTop: 20 }}>
      <Grid item xs={12}>
        <Table
          emtpyTable={<p>No Species found</p>}
          isLoading={isLoading}
          tableData={speciesList}
          tableHeaders={tableHeaders}
          rowRenderer={renderRow}
          filter={false}
        />
      </Grid>
    </Grid>
  );
}

SpeciesListTable.propTypes = {
  speciesList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      species: PropTypes.string,
      subtax1: PropTypes.string,
      taxon1: PropTypes.string,
      author1: PropTypes.string,
      subtax2: PropTypes.string,
      taxon2: PropTypes.string,
      author2: PropTypes.string,
      cultivar: PropTypes.string,
      genus: PropTypes.PropTypes.shape({
        id: PropTypes.number,
        genusName: PropTypes.string,
      }),
    })
  ),
  isLoading: PropTypes.bool,
  linkToSpecies: PropTypes.func,
  linkToGenus: PropTypes.func,
  tableColumns: PropTypes.arrayOf(PropTypes.shape({})),
  filterPlaceholder: PropTypes.string,
};

SpeciesListTable.defaultProps = {
  filterPlaceholder: "Search by Species or Genus",
  speciesList: [],
  isLoading: false,
  tableColumns: [
    { id: "species", label: "Species" },
    { id: "genus", label: "Genus" },
    { id: "subtax1", label: "Sub Tax 1" },
    { id: "taxon1", label: "Taxon 1" },
    { id: "author1", label: "Author 1" },
    { id: "subtax2", label: "Sub Tax 2" },
    { id: "taxon2", label: "Taxon 2" },
    { id: "author2", label: "Author 2" },
    { id: "cultivar", label: "Cultivar" },
  ],
};

export default SpeciesListTable;
